import React from 'react';
import './loader.css'; // Import your CSS file for styling

const YouTubeLoader = ({ loading }) => {
  return (
    <div className={`loader-container${loading ? ' active' : ''}`}>
      <div className="loader-bar"></div>
    </div>
  );
};

export default YouTubeLoader;

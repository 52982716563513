import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FloatingButton from "./FloatingButton";

const ScrollTop = () => {
    const [isvisible, setIsvisible] = useState(false);

    const toggleVisibility = () => {
        setIsvisible(window.pageYOffset > 300);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    // Function to initiate the call
    const initiateCall = () => {
        window.location.href = "tel:+255675893405";
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    const setting = useSelector((state) => state.setting);

    return (
        <div className="scroll-to-top">
            <div onClick={isvisible ? scrollToTop : initiateCall} className="back-top-container">
                <FloatingButton onClick={isvisible ? scrollToTop : initiateCall} isScrollToTop={isvisible} />
            </div>
        </div>
    );
};

export default ScrollTop;

import React from 'react';
import './FloatingButton.css';
import { IoChevronUp } from 'react-icons/io5';
import { FaPhoneAlt } from 'react-icons/fa'; // Import the FaPhoneAlt icon

const FloatingButton = ({ onClick, isScrollToTop }) => {
  return (
    <div className={`floating-button ${isScrollToTop ? "scroll-to-top" : "floating-call"}`} onClick={onClick}>
      {isScrollToTop ? <IoChevronUp size={20} color="#fff" /> : <FaPhoneAlt size={20} color="#fff" />} {/* Replace the phone icon */}
    </div>
  );
};

export default FloatingButton;
